<template>
  <section>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-form @submit.prevent="onSubmit">
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
        ></v-progress-linear>
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col cols="6">
              <validated-text-field
                :autofocus="true"
                rules="required|duplicate_name"
                v-model="site.name"
                label="Name"
              ></validated-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="v-input__slot">
                <label-component
                  label="Address Lookup"
                  :labelBold="false"
                  :valueIndent="false"
                >
                  <input
                    label="Lookup"
                    v-model="site.address"
                    ref="autocomplete"
                    type="text"
                    placeholder="type all or part of a place"
                    class="v-text-field search-location"
                  />
                </label-component>
              </div>
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="12" sm="2">
              <validated-text-field
                v-model="site.streetNumber"
                rules="required"
                label="Number"
              />
            </v-col>
            <v-col cols="12" sm="9">
              <validated-text-field
                v-model="site.street"
                rules="required"
                label="Street"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="12" sm="6">
              <validated-text-field
                v-model="site.city"
                rules="required"
                label="City"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <validated-select-field
                rules="required"
                :items="states"
                item-text="name"
                item-value="abbreviation"
                v-model="site.state"
                label="State"
              ></validated-select-field>
            </v-col>
            <v-col cols="12" sm="2">
              <validated-text-field
                v-model="site.postalCode"
                rules="required"
                label="Postal Code"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="12" sm="3">
              <validated-text-field
                v-model="site.longitude"
                rules="required"
                label="Longitude"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <validated-text-field
                v-model="site.latitude"
                rules="required"
                label="Latitude"
              />
            </v-col>

            <v-col cols="12" sm="3">
              <validated-select-field
                rules="required"
                :items="timezones"
                :filter="timezoneFilter"
                item-text="name"
                item-value="id"
                v-model="site.timeZoneId"
                label="Time Zone"
              ></validated-select-field>
            </v-col>
            <v-col cols="12" sm="3">
              <validated-select-field
                :items="weatherStations"
                item-text="name"
                item-value="id"
                v-model="site.weatherStationId"
                label="Weather Station"
                rules="required"
              ></validated-select-field>
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="12" sm="4">
              <validated-select-field
                rules="required"
                :items="utilityCompanies"
                :item-text="(item) => item.name"
                :item-value="(item) => item.id"
                v-model="site.utilityCompanyIds"
                label="Utility"
                multiple
              ></validated-select-field>
            </v-col>

            <v-col cols="12" sm="4">
              <validated-select-field
                :items="marketContexts"
                item-text="name"
                item-value="id"
                v-model="site.marketContextId"
                label="Market Context"
              ></validated-select-field>
            </v-col>
            <v-col cols="12" sm="2">
              <validated-text-field
                v-model="site.kwhCost"
                rules="required"
                label="Cost per kWh ($)"
              />
            </v-col>
            <v-col cols="12" sm="2">
              <validated-text-field
                v-model="site.thermCost"
                rules
                label="Cost per Therm ($)"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="12" sm="4">
              <validated-select-field
                rules="required"
                :items="siteTypes"
                item-text="name"
                item-value="id"
                v-model="site.siteTypeId"
                label="Building Type"
              ></validated-select-field>
            </v-col>
            <v-col cols="12" sm="2">
              <validated-text-field
                v-model="site.squareFootage"
                rules="required"
                label="Sq. Ft."
                type="number"
                min="0"
                max="50000"
                step="500"
              />
            </v-col>

            <v-col cols="12" sm="2">
              <validated-text-field
                v-model="site.floors"
                rules="required"
                label="Floors"
                type="number"
                min="0"
                max="100"
                step="1"
              />
            </v-col>
            <v-col cols="12" sm="2">
              <validated-text-field
                v-model="site.occupants"
                rules="required"
                label="Occupants"
                type="number"
                min="0"
                max="10000"
                step="50"
              />
            </v-col>
            <v-col cols="12" sm="2">
              <validated-select-field
                rules="required"
                :items="years"
                v-model="site.year"
                label="Year Built"
              ></validated-select-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-label>Open Time:</v-label>
              <br />
              <v-time-picker
                v-model="selectedSiteStartStopTime.startStopTime.siteStartTime"
                format="ampm"
                width="15vw"
                landscape
                color="blue-grey lighten-2"
                @input="handleTimeChange"
                scrollable
                :disabled="!selectedSiteStartStopTime.isOpen"
              ></v-time-picker>
            </v-col>
            <v-col cols="4">
              <v-label>Closing Time:</v-label>
              <br />
              <v-time-picker
                v-model="selectedSiteStartStopTime.startStopTime.siteEndTime"
                format="ampm"
                width="15vw"
                landscape
                color="blue-grey lighten-3"
                :disabled="!selectedSiteStartStopTime.isOpen"
                @input="handleTimeChange"
                scrollable
              ></v-time-picker>
            </v-col>
            <v-col cols="2">
              <v-switch
                :label="openClosedLabel"
                v-model="selectedSiteStartStopTime.isOpen"
                color="success"
                @change="processOpenClose($event)"
              ></v-switch>
            </v-col>
            <v-col cols="2">
              <validated-select-field
                rules
                :items="siteStartStopTimes"
                :item-text="(item) => item.dayOfWeekName"
                :return-object="true"
                v-model="selectedSiteStartStopTime"
                label="Select days"
              ></validated-select-field>
            </v-col>
          </v-row>
        </v-sheet>

        <v-sheet elevation="2" class="mt-4 pa-4">
          <v-row>
            <v-col cols="12" sm="8">
              <div class="title">Contacts (optional)</div>
            </v-col>
            <v-col sm="2">
              <v-btn color="primary" @click="addContact">Add Contact</v-btn>
            </v-col>
          </v-row>
          <v-row
            justify="space-between"
            v-for="(contact, index) in site.createSiteContactResourceModels"
            :key="contact.contactMethodId"
          >
            <v-col>
              <v-row>
                <v-col cols="12" sm="4">
                  <validated-text-field
                    v-model="contact.siteContactName"
                    rules="required"
                    label="Contact Name"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <validated-text-field
                    v-model="contact.siteContactTitle"
                    rules="required"
                    label="Contact Title"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <validated-select-field
                    v-if="loading === false"            
                    :items="siteContactNotificationFrequencies"
                    v-model="contact.siteContactNotificationFrequencyId"
                    label="Contact Frequency"
                    item-text="name"
                    item-value="id"
                  ></validated-select-field>
                </v-col>
                <v-col sm="2">
                  <v-btn color="primary" @click="removeContact(index)"
                    >Remove Contact</v-btn
                  >
                </v-col>
              </v-row>
              <v-row
                v-for="(
                  contactMethod, methodIndex
                ) in contact.createSiteContactMethodResourceModels"
                :key="contactMethod.contactMethodId"
              >
                <v-col cols="12" sm="4">
                  <validated-select-field
                    :items="contactTypes"
                    v-model="contactMethod.contactMethodId"
                    label="Contact Type"
                  ></validated-select-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <validated-text-field
                    v-if="contactMethod.contactMethodId !== 2"
                    v-model="contactMethod.siteContactMethodValue"
                    rules="required"
                    label="Number"
                    v-mask="phoneMask"
                    masked
                  />

                  <validated-text-field
                    v-if="contactMethod.contactMethodId === 2"
                    v-model="contactMethod.siteContactMethodValue"
                    rules="required|email"
                    label="Email"
                  />
                </v-col>
                <v-col cols="12" sm="2">
                  <v-switch
                    v-model="contactMethod.isPreferred"
                    :label="`Preferred`"
                    @change="togglePrefer(methodIndex, contactMethod, contact)"
                  ></v-switch>
                </v-col>
                <v-col sm="2" v-if="methodIndex === 0">
                  <v-btn color="primary" @click="addContactMethod(contact)" v-bind:disabled="contact.createSiteContactMethodResourceModels.length >= 2"
                    >Add Method</v-btn
                  >
                </v-col>
                <v-col sm="2" v-if="methodIndex > 0">
                  <v-btn
                    color="primary"
                    @click="removeContactMethod(contact, methodIndex)"
                    >Remove Method</v-btn
                  >
                </v-col>
              </v-row>
              <v-divider class="mt-6"></v-divider>
            </v-col>
          </v-row>
        </v-sheet>

        <v-sheet elevation="2" class="mt-4 pa-4">
          <v-row>
            <v-col cols="12" sm="8">
              <div class="title">Site Image (optional)</div>
            </v-col>
            <v-col cols="12" sm="8">
              <v-file-input
                :rules="rules"
                small-chips
                label="Select Image For Site (.jpg, .jpeg, .png, .bmp)"
                accept=".jpg, .jpeg, .png, .bmp"
                prepend-icon="mdi-camera"
                v-model="siteImageFile"
                @change="showFilePreview($event)" 
                class="pr-4" >
              </v-file-input>
              <p class="text-caption font-weight-light">*Images are cropped at 345x200px. For best results use a 3:4 aspect ratio.</p>
            </v-col>
          </v-row>
          <v-img v-if="imagePreviewUrl" :src="imagePreviewUrl" max-height="200" max-width="345"></v-img>
        </v-sheet>

        <v-row>
          <v-col>
            <v-btn
              :disabled="invalid"
              type="submit"
              color="primary"
              class="mr-5">Save
              </v-btn>
            <v-btn :to="`/sites`">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </section>
</template>

<style>
.search-location {
  display: block;
  width: 80vw;
  outline: none;
  text-align: left;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.42);
  border-width: 0 0 thin 0;
}
</style>
<script>
import { Loader } from "google-maps";
import { ValidationObserver, Validator } from "vee-validate";
import { mask } from "vue-the-mask";

import api from "../_api";
import Label from "@/modules/controls/_components/Label";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ValidatedSelectField from "@/components/Fields/ValidatedSelectField";

import { createSiteResourceModel } from "../_resourceModels/createSiteResourceModel";
import { createSiteContactResourceModel } from "../_resourceModels/createSiteContactResourceModel";
import { createSiteContactMethodResourceModel } from "../_resourceModels/createSiteContactMethodResourceModel";
import { createSiteStartStopTimeResourceModel } from "../_resourceModels/createSiteStartStopTimeResourceModel";
import { createStartStopTimeResourceModel } from "../_resourceModels/createStartStopTimeResourceModel";

export default {
  components: {
    "validation-observer": ValidationObserver,
    "validated-text-field": ValidatedTextField,
    "validated-select-field": ValidatedSelectField,
    "label-component": Label,
  },

  directives: {
    mask,
  },

  data() {
    return {
      rules: [
        value => !value || value.size < 3000000 || 'Image size must be less than 3MB.',
      ],
      loading: true,
      valid: true,
      server_errors: [],
      phoneMask: "###-###-####",
      emailMethod: false,

      states: [],
      sites: [],
      weatherStations: [],
      timezones: [],
      utilityCompanies: [],
      marketContexts: [],
      siteTypes: [],
      siteStartStopTimes: [],

      contactTypes: [
        { value: 3, text: "Text" },
        { value: 2, text: "Email" },
      ],

      years: this.getYears(),
      pickerLabels: { start: "Open Time", end: "Closing Time" },
      google: null,
      autocompleted: null,
      site: new createSiteResourceModel(),
      siteName: null,
      selectedSiteStartStopTime: { startStopTime: {} },
      presetStartStopTimes: [],
      openClosedLabel: "Closed",
      dayOfWeek: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      siteImageFile: null,
      imagePreviewUrl: null,
    };
  },

  mounted() {
    const self = this;

    if (self.google === null) {
      let loader = new Loader("AIzaSyCda-Sl20-2xJ-k7nCtmRiOTXWRgfS6P60", {
        libraries: ["places"],
      });
      loader.load().then(function (goog) {
        self.google = { ...goog };
        self.autocompleted = new self.google.maps.places.Autocomplete(
          self.$refs.autocomplete,
          { types: ["geocode"] }
        );

        self.autocompleted.addListener("place_changed", () => {
          let place = self.autocompleted.getPlace();
          let addressDetail = self.mapPlaceComponents(place);

          self.site.latitude = addressDetail.lat;
          self.site.longitude = addressDetail.lng;
          self.site.address = addressDetail.address;
          self.site.streetNumber = addressDetail.streetNumber;
          self.site.street = addressDetail.street;
          self.site.city = addressDetail.city;
          self.site.state = addressDetail.state;
          self.site.postalCode = addressDetail.postalCode;
        });
      });
    }
  },

  async created() {
    this.getWeatherstations();
    this.getTimezones();
    this.getUtilityCompanies();
    this.getSiteTypes();
    this.getMarketContexts();
    this.getStates();
    this.getSiteContactFrequencies();
    this.getSites();
    this.registerCustomValidators();
    this.siteStartStopTimes = this.mapSiteStartStopTime();
    this.selectedSiteStartStopTime = this.siteStartStopTimes[1];
  },

  methods: {
    processOpenClose(event) {
      console.log(event);
      this.openClosedLabel =
        this.selectedSiteStartStopTime.isOpen === true ? "Open" : "Closed";

      if (this.selectedSiteStartStopTime.dayOfWeekName === "All") {
        this.resetAllSiteStartStopTimes(this.selectedSiteStartStopTime);
      }
      console.log(this.openClosedLabel);
    },

    resetAllSiteStartStopTimes(selectedSiteStartStopTime) {
      console.log(selectedSiteStartStopTime);
      let resetTimes = this.siteStartStopTimes.map((s, i) => {
        return this.newStartStopTime(
          i - 1,
          s.dayOfWeekName,
          selectedSiteStartStopTime.isOpen,
          JSON.parse(JSON.stringify(selectedSiteStartStopTime.startStopTime))
        );
      });

      this.siteStartStopTimes = resetTimes;
    },

    handleTimeChange(event) {
      console.log(`time changed to: ${event}`);
      if (this.selectedSiteStartStopTime.dayOfWeekName === "All") {
        this.resetAllSiteStartStopTimes(this.selectedSiteStartStopTime);
      }
    },

    getStates() {
      this.states = api.getStates();
    },

    async getWeatherstations() {
      try {
        let response = await api.getWeatherStations();
        this.weatherStations = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getSites() {
      try {
        let response = await api.getSites();
        this.sites = response;
        this.loading = false;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
        this.loading = false;
      }
    },

    async getTimezones() {
      try {
        let response = await api.getTimezones();
        this.timezones = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getUtilityCompanies() {
      try {
        let response = await api.getUtilityCompanies();
        this.utilityCompanies = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getMarketContexts() {
      try {
        let response = await api.getMarketContexts();
        this.marketContexts = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getSiteTypes() {
      try {
        let response = await api.getSiteTypes();
        this.siteTypes = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getSiteContactFrequencies() {
      try {
        let response = await api.getSiteContactNotificationFrequencies();
        this.siteContactNotificationFrequencies = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    mapSiteStartStopTime() {
      let mappedTimes = [];

      for (let i = 0; i < this.dayOfWeek.length; i++) {
        let newSsTime = new createStartStopTimeResourceModel(
          "07:00:00",
          "19:00:00"
        );
        let newSssTime = this.newStartStopTime(
          i,
          this.dayOfWeek[i],
          false,
          newSsTime
        );
        mappedTimes.push(newSssTime);
      }
      //add the 'all' pick
      let allTime = new createStartStopTimeResourceModel(
        "07:00:00",
        "19:00:00"
      );
      mappedTimes.unshift(
        this.newStartStopTime(mappedTimes.length + 1, "All", false, allTime)
      );
      return mappedTimes;
    },

    newStartStopTime(index, dayOfWeekName, isOpen, startStopTime) {
      let newSssTime = new createSiteStartStopTimeResourceModel(
        0,
        index,
        dayOfWeekName,
        isOpen
      );

      startStopTime.id = 0; //TODO: make an update resource model
      newSssTime.startStopTime = startStopTime;
      newSssTime.id = 0;
      return newSssTime;
    },

    async onSubmit() {
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }

      try {
        this.loading = true;
        console.log("Submit form to API endpoint, if it is valid.");
        this.site.siteStartStopTime = this.mapUpdatedStartStopTime(
          this.siteStartStopTimes
        );
        
        await api.addSite(this.site).then((result) => {
          if (this.siteImageFile) {
            api.updateSiteImage(result, this.siteImageFile);
            this.loading = false;
            this.$toast.show("Site updated.", null, 'success');
            this.$router.push({ name: "SiteIndexRoute" });
          } else {
            this.loading = false;
            this.$toast.show("Site updated.", null, 'success');
            this.$router.push({ name: "SiteIndexRoute" });
          }
        });
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    mapUpdatedStartStopTime(sSSTimes) {
      var openSSSTimes = sSSTimes.filter(
        (t) => t.isOpen === true && t.dayOfWeekName !== "All"
      );
      console.log(openSSSTimes);
      return openSSSTimes;
    },

    mapPlaceComponents(pl) {
      const addressDetail = (place) => {
        const parseComponent = (type, nameForm) => {
          let value = null;
          place.address_components.some((object) => {
            if (object.types.length > 0) {
              if (object.types[0] === type) {
                value = object[nameForm];
              }
            }
          });

          return value;
        };

        let address = {
          streetNumber: parseComponent("street_number", "long_name"),
          street: parseComponent("route", "long_name"),
          city: parseComponent("locality", "long_name"),
          state: parseComponent("administrative_area_level_1", "short_name"),
          postalCode: parseComponent("postal_code", "long_name"),
          address: place.formatted_address,
          lat: Number.parseFloat(place.geometry.location.lat()).toFixed(4),
          lng: Number.parseFloat(place.geometry.location.lng()).toFixed(4),
        };

        return address;
      };
      return addressDetail(pl);
    },

    getYears() {
      let thisYear = new Date().getFullYear(),
        yearList = [];
      let startYear = 1900;
      while (startYear <= thisYear) {
        yearList.push(thisYear--);
      }
      return yearList;
    },

    addContact() {
      let newContact = new createSiteContactResourceModel();
      this.site.createSiteContactResourceModels.push(newContact);
    },

    removeContact(index) {
      this.site.createSiteContactResourceModels.splice(index, 1);
    },

    addContactMethod(contact) {
      let newContactMethod = new createSiteContactMethodResourceModel(
        null,
        null,
        false
      );
      contact.createSiteContactMethodResourceModels.push(newContactMethod);
    },

    removeContactMethod(contact, index) {
      contact.createSiteContactMethodResourceModels.splice(index, 1);
    },

    togglePrefer(index, contactMethod, contact) {
      let value = contactMethod.isPreferred;

      if (
        value === false ||
        contact.createSiteContactMethodResourceModels.length === 1
      ) {
        return;
      } else {
        for (
          let i = 0;
          i < contact.createSiteContactMethodResourceModels.length;
          i++
        ) {
          if (i === index) {
            continue;
          } else {
            contact.createSiteContactMethodResourceModels[
              i
            ].isPreferred = false;
          }
        }
      }
    },

    showFilePreview(e) {
      this.imagePreviewUrl = URL.createObjectURL(e);
    },

    errorSummary(error) {
      return error.response
        ? error.response.data.Errors
          ? error.response.data.Errors
          : error.response.data.errors
          ? error.response.data.errors
          : [error]
        : [error];
    },

    timezoneFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },

    registerCustomValidators() {
      let self = this;
      Validator.extend("duplicate_name", {
        // Custom validation message
        getMessage: (field) =>
          `${field} is already used by another site.  Enter a different name.`,
        // Custom validation rule
        validate: (value) =>
          new Promise((resolve) => {
            let valid = true;
            if (self.sites) {
              let existingNames = self.sites.map((a) => a.name);
              valid = existingNames.indexOf(value) === -1;
            }

            resolve({
              valid: valid,
            });
          }),
      });

      // Validator.extend("duplicate_contactMethod", {
      //   // Custom validation message
      //   getMessage: (field) =>
      //     `${field} duplicate contact types are not allowed.`,
      //   // Custom validation rule
      //   validate: (value) =>
      //     new Promise((resolve) => {
      //       let valid = true;
      //       if (self.site.createSiteContactResourceModels.length > 0) {
      //         self.site.createSiteContactResourceModels.forEach((contact) => {
      //           if (contact.createSiteContactMethodResourceModels.length > 0) {
      //             let existingTypes =
      //               contact.createSiteContactMethodResourceModels.map(
      //                 (method) => {
      //                   return method.contactMethodId;
      //                 }
      //               );
      //             valid =
      //               existingTypes.filter((type) => type === value).length === 1;
      //             console.log(valid);
      //           }
      //         });
      //       }

      //       resolve({
      //         valid: valid,
      //       });
      //     }),
      // });
    },
  },
};
</script>